import http from '../http-common'

export default class TicketServices {
  async getAllAttendancesForUser(){
    let res = await http.get(`v1/ticket`);
    return res.data;
  }

  async getAllTicketsByUserId(userId, companyId){
    let res = await http.get(`v1/ticket/get-ticket-user?userId=${userId}&companyId=${companyId}`);
    return res.data;
  }

  async getMessageForTicketWithPagination(attendanceId, pageNumber, pageSize) {
    let res = await http.get(`v1/ticket/${attendanceId}?PageNumber=${pageNumber}&PageSize=${pageSize}`);
    return res.data;
  }

  async getMessageForTicket(attendanceId) {
    let res = await http.get(`v1/ticket/${attendanceId}/all`);
    return res.data;
  }

  async startTicket(attendanceId) {
    let res = await http.post(`v1/ticket/${attendanceId}/start-ticket`);
    return res.data;
  }

  async changeStateTicket(attendanceId, state) {
    let res = await http.post(`v1/ticket/${attendanceId}/${state}/change-status-ticket`);
    return res.data;
  }

  async sendEmailTicket(attendanceId, channelId, data, reply) {
    if(reply){
      let res = await http.post(`v1/ticket/${attendanceId}/reply-ticket/${channelId}`, data);
      return res.data;
    }
    let res = await http.post(`v1/ticket/${attendanceId}/forward-ticket/${channelId}`, data);
    return res.data;
  }

  async createTicketAndSendMessage(queueId, channelId, data) {
    let res = await http.post(`v1/ticket/create-ticket-active?queueId=${queueId}&channelId=${channelId}`, data);
    return res.data;
  }


  async finishTicket(attendanceId, savedTabulation) {
    let res = await http.post(`v1/ticket/${attendanceId}/${savedTabulation}/finish`);
    return res.data;
  }

  async transferTicket(attendanceId, queueId){
    let res = await http.put(`v1/ticket/${attendanceId}/transfer/${queueId}`);
    return res.data
  }

  async transferTicketToAnotherQueue(data){
    let res = await http.put(`v1/ticket/tranfer-ticket`, data);
    return res.data
  }

  async transferTicketsToOriginalQueue(data){
    let res = await http.put(`v1/ticket/transfer-back-ticket`, data)
    return res.data
  }

  async transferDistributedTicketsBackToQueue(){
    let res = await http.put(`v1/ticket/transfer-tickets-queue`)
    return res.data
  }
}