let ENV = {};
//Os dados do facebook são para empresa WTF

if (window.location.hostname === "cosmos.wtfapps.dev.br") {
  ENV = {
    APP_NAME: "Plataforma Digital",
    APP_ENV: "development",
    BASE_URL: "https://apidigital.wtfapps.dev.br/api",
    BASE_URL_SOCKET: "https://apidigital.wtfapps.dev.br/hub",
    BASE_URL_WIDGET: "https://cosmos.wtfapps.dev.br",
    FACEBOOK_APP_ID: "1164896447329254",
    FACEBOOK_APP_SECRET: "e7d0ba50c868965360e3a3276d8a98cd",
    FACEBOOK_VERIFICATION_TOKEN:
      "89bfab402f5a0b5062f436d9ab9f39861ba8242a68b4508e0d1296e520a93d08",
    FACEBOOK_APP_ACCESS_TOKEN: "1164896447329254|2zjs-0hApZrTB2dXkLj_mIxnUMQ",
    BASE_URL_SOCKET_WIDGET: "https://apidigital.wtfapps.dev.br/hub/widget",
    TENANT: "wtf",
    URL_AWS_BUCKET: "https://digital-storage-stag.s3.us-east-2.amazonaws.com/",
  };
} else if (window.location.hostname === "wtf.cosmos.inf.br") {
  let tenant = "wtf";
  ENV = {
    TENANT: tenant,
    APP_NAME: "Plataforma Digital",
    APP_ENV: "development",
    BASE_URL: "https://wtf-api.cosmos.inf.br/api",
    BASE_URL_SOCKET: "https://wtf-api.cosmos.inf.br/hub?tenant=" + tenant,
    BASE_URL_WIDGET: "https://wtf.cosmos.inf.br",
    FACEBOOK_APP_ID: "1164896447329254",
    FACEBOOK_APP_SECRET: "e7d0ba50c868965360e3a3276d8a98cd",
    FACEBOOK_VERIFICATION_TOKEN:
      "89bfab402f5a0b5062f436d9ab9f39861ba8242a68b4508e0d1296e520a93d08",
    FACEBOOK_APP_ACCESS_TOKEN: "1164896447329254|2zjs-0hApZrTB2dXkLj_mIxnUMQ",
    BASE_URL_SOCKET_WIDGET:
      "https://wtf-api.cosmos.inf.br/hub/widget?tenant=" + tenant,
    URL_AWS_BUCKET:
      "https://cosmos-storage-wtf-prod.s3.us-east-2.amazonaws.com/",
  };
} else if (window.location.hostname === "creasp.cosmos.inf.br") {
  let tenant = "creasp";
  ENV = {
    TENANT: tenant,
    APP_NAME: "Plataforma Digital",
    APP_ENV: "development",
    BASE_URL: "https://creasp-api.cosmos.inf.br/api",
    BASE_URL_SOCKET: "https://creasp-api.cosmos.inf.br/hub?tenant=" + tenant,
    BASE_URL_WIDGET: "https://creasp.cosmos.inf.br",
    FACEBOOK_APP_ID: "1164896447329254",
    FACEBOOK_APP_SECRET: "e7d0ba50c868965360e3a3276d8a98cd",
    FACEBOOK_VERIFICATION_TOKEN:
      "89bfab402f5a0b5062f436d9ab9f39861ba8242a68b4508e0d1296e520a93d08",
    FACEBOOK_APP_ACCESS_TOKEN: "1164896447329254|2zjs-0hApZrTB2dXkLj_mIxnUMQ",
    BASE_URL_SOCKET_WIDGET:
      "https://creasp-api.cosmos.inf.br/hub/widget?tenant=" + tenant,
    URL_AWS_BUCKET:
      "https://cosmos-storage-creasp-prod.s3.us-east-2.amazonaws.com/",
  };
} else if (window.location.hostname === "stag.cosmos.inf.br") {
  let tenant = "stag";
  ENV = {
    TENANT: tenant,
    APP_NAME: "Plataforma Digital",
    APP_ENV: "development",
    BASE_URL: "https://api-stag.cosmos.inf.br/api",
    BASE_URL_SOCKET: "https://api-stag.cosmos.inf.br/hub?tenant=" + tenant,
    BASE_URL_WIDGET: "https://stag.cosmos.inf.br",
    FACEBOOK_APP_ID: "1164896447329254",
    FACEBOOK_APP_SECRET: "e7d0ba50c868965360e3a3276d8a98cd",
    FACEBOOK_VERIFICATION_TOKEN:
      "89bfab402f5a0b5062f436d9ab9f39861ba8242a68b4508e0d1296e520a93d08",
    FACEBOOK_APP_ACCESS_TOKEN: "1164896447329254|2zjs-0hApZrTB2dXkLj_mIxnUMQ",
    BASE_URL_SOCKET_WIDGET:
      "https://api-stag.cosmos.inf.br/hub/widget?tenant=" + tenant,
    URL_AWS_BUCKET:
      "https://tel-cosmos-storage-creasp-stag.s3.us-east-2.amazonaws.com/",
  };
} else if (window.location.hostname === "allos.cosmos.inf.br") {
  let tenant = "aliansce";
  ENV = {
    TENANT: tenant,
    APP_NAME: "Plataforma Digital",
    APP_ENV: "development",
    BASE_URL: "https://allos-api.cosmos.inf.br/api",
    BASE_URL_SOCKET: "https://allos-api.cosmos.inf.br/hub?tenant=" + tenant,
    BASE_URL_WIDGET: "https://allos.cosmos.inf.br",
    FACEBOOK_APP_ID: "1164896447329254",
    FACEBOOK_APP_SECRET: "e7d0ba50c868965360e3a3276d8a98cd",
    FACEBOOK_VERIFICATION_TOKEN:
      "89bfab402f5a0b5062f436d9ab9f39861ba8242a68b4508e0d1296e520a93d08",
    FACEBOOK_APP_ACCESS_TOKEN: "1164896447329254|2zjs-0hApZrTB2dXkLj_mIxnUMQ",
    BASE_URL_SOCKET_WIDGET:
      "https://allos-api.cosmos.inf.br/hub/widget?tenant=" + tenant,
    URL_AWS_BUCKET:
      "https://cosmos-storage-allos-prod.s3.us-east-2.amazonaws.com/",
  };
} else if (window.location.hostname === "hughes.cosmos.inf.br") {
  let tenant = "hughes";
  ENV = {
    TENANT: tenant,
    APP_NAME: "Plataforma Digital",
    APP_ENV: "development",
    BASE_URL: "https://hughes-api.cosmos.inf.br/api",
    BASE_URL_SOCKET: "https://hughes-api.cosmos.inf.br/hub?tenant=" + tenant,
    BASE_URL_WIDGET: "https://hughes.cosmos.inf.br",
    FACEBOOK_APP_ID: "1164896447329254",
    FACEBOOK_APP_SECRET: "e7d0ba50c868965360e3a3276d8a98cd",
    FACEBOOK_VERIFICATION_TOKEN:
      "89bfab402f5a0b5062f436d9ab9f39861ba8242a68b4508e0d1296e520a93d08",
    FACEBOOK_APP_ACCESS_TOKEN: "1164896447329254|2zjs-0hApZrTB2dXkLj_mIxnUMQ",
    BASE_URL_SOCKET_WIDGET:
      "https://hughes-api.cosmos.inf.br/hub/widget?tenant=" + tenant,
    URL_AWS_BUCKET:
      "https://cosmos-storage-hughes-prod.s3.us-east-2.amazonaws.com/",
  };
} else if (window.location.hostname === "localhost") {
  let tenant = "stag";
  ENV = {
    TENANT: tenant,
    APP_NAME: "Plataforma Digital",
    APP_ENV: "development",
    BASE_URL: "https://localhost:7050/api",
    BASE_URL_SOCKET: "https://localhost:7050/hub?tenant=" + tenant,
    BASE_URL_WIDGET: "http://127.0.0.1:8080",
    FACEBOOK_APP_ID: "1164896447329254",
    FACEBOOK_APP_SECRET: "e7d0ba50c868965360e3a3276d8a98cd",
    FACEBOOK_VERIFICATION_TOKEN:
      "89bfab402f5a0b5062f436d9ab9f39861ba8242a68b4508e0d1296e520a93d08",
    FACEBOOK_APP_ACCESS_TOKEN: "1164896447329254|2zjs-0hApZrTB2dXkLj_mIxnUMQ",
    BASE_URL_SOCKET_WIDGET:
      "https://localhost:7050/hub/widget?tenant=" + tenant,
    URL_AWS_BUCKET:
      "https://tel-cosmos-storage-creasp-stag.s3.us-east-2.amazonaws.com/",
  };
} else if (window.location.hostname === "prevident.cosmos.inf.br") {
  let tenant = "prevident";
  ENV = {
    TENANT: tenant,
    APP_NAME: "Plataforma Digital",
    APP_ENV: "production",
    BASE_URL: "https://prevident-api.cosmos.inf.br/api",
    BASE_URL_SOCKET: "https://prevident-api.cosmos.inf.br/hub?tenant=" + tenant,
    BASE_URL_WIDGET: "https://prevident.cosmos.inf.br",
    FACEBOOK_APP_ID: "1164896447329254",
    FACEBOOK_APP_SECRET: "e7d0ba50c868965360e3a3276d8a98cd",
    FACEBOOK_VERIFICATION_TOKEN:
      "89bfab402f5a0b5062f436d9ab9f39861ba8242a68b4508e0d1296e520a93d08",
    FACEBOOK_APP_ACCESS_TOKEN: "1164896447329254|2zjs-0hApZrTB2dXkLj_mIxnUMQ",
    BASE_URL_SOCKET_WIDGET:
      "https://prevident-api.cosmos.inf.br/hub/widget?tenant=" + tenant,
    URL_AWS_BUCKET:
      "https://cosmos-storage-prevident-prod.s3.us-east-2.amazonaws.com/",
  };
} else if (window.location.hostname === "aformula.cosmos.inf.br") {
  let tenant = "formula";
  ENV = {
    TENANT: tenant,
    APP_NAME: "Plataforma Digital",
    APP_ENV: "production",
    BASE_URL: "https://aformula-api.cosmos.inf.br/api",
    BASE_URL_SOCKET: "https://aformula-api.cosmos.inf.br/hub?tenant=" + tenant,
    BASE_URL_WIDGET: "https://aformula.cosmos.inf.br",
    FACEBOOK_APP_ID: "1164896447329254",
    FACEBOOK_APP_SECRET: "e7d0ba50c868965360e3a3276d8a98cd",
    FACEBOOK_VERIFICATION_TOKEN:
      "89bfab402f5a0b5062f436d9ab9f39861ba8242a68b4508e0d1296e520a93d08",
    FACEBOOK_APP_ACCESS_TOKEN: "1164896447329254|2zjs-0hApZrTB2dXkLj_mIxnUMQ",
    BASE_URL_SOCKET_WIDGET:
      "https://aformula-api.cosmos.inf.br/hub/widget?tenant=" + tenant,
    URL_AWS_BUCKET:
      "https://cosmos-storage-aformula-prod.s3.us-east-2.amazonaws.com/",
  };
} else if (window.location.hostname === "serasa.cosmos.inf.br") {
  let tenant = "serasa";
  ENV = {
    TENANT: tenant,
    APP_NAME: "Plataforma Digital",
    APP_ENV: "production",
    BASE_URL: "https://serasa-api.cosmos.inf.br/api",
    BASE_URL_SOCKET: "https://serasa-api.cosmos.inf.br/hub?tenant=" + tenant,
    BASE_URL_WIDGET: "https://serasa.cosmos.inf.br",
    FACEBOOK_APP_ID: "1164896447329254",
    FACEBOOK_APP_SECRET: "e7d0ba50c868965360e3a3276d8a98cd",
    FACEBOOK_VERIFICATION_TOKEN:
      "89bfab402f5a0b5062f436d9ab9f39861ba8242a68b4508e0d1296e520a93d08",
    FACEBOOK_APP_ACCESS_TOKEN: "1164896447329254|2zjs-0hApZrTB2dXkLj_mIxnUMQ",
    BASE_URL_SOCKET_WIDGET:
      "https://serasa-api.cosmos.inf.br/hub/widget?tenant=" + tenant,
    URL_AWS_BUCKET:
      "https://tel-cosmos-serasa-prod-v2.s3.us-east-2.amazonaws.com/",
  };
} else if (window.location.hostname === "clube.cosmos.inf.br") {
  let tenant = "espanhol";
  ENV = {
    TENANT: tenant,
    APP_NAME: "Plataforma Digital",
    APP_ENV: "development",
    BASE_URL: "https://clube-api.cosmos.inf.br/api",
    BASE_URL_SOCKET: "https://clube-api.cosmos.inf.br/hub?tenant=" + tenant,
    BASE_URL_WIDGET: "https://clube.cosmos.inf.br",
    FACEBOOK_APP_ID: "1164896447329254",
    FACEBOOK_APP_SECRET: "e7d0ba50c868965360e3a3276d8a98cd",
    FACEBOOK_VERIFICATION_TOKEN:
      "89bfab402f5a0b5062f436d9ab9f39861ba8242a68b4508e0d1296e520a93d08",
    FACEBOOK_APP_ACCESS_TOKEN: "1164896447329254|2zjs-0hApZrTB2dXkLj_mIxnUMQ",
    BASE_URL_SOCKET_WIDGET:
      "https://clube-api.cosmos.inf.br/hub/widget?tenant=" + tenant,
    URL_AWS_BUCKET:
      "https://cosmos-storage-clube-prod.s3.us-east-2.amazonaws.com/",
  };
} else if (window.location.hostname === "pms.cosmos.inf.br") {
  let tenant = "pms";
  ENV = {
    TENANT: tenant,
    APP_NAME: "Plataforma Digital",
    APP_ENV: "production",
    BASE_URL: "https://pms-api.cosmos.inf.br/api",
    BASE_URL_SOCKET: "https://pms-api.cosmos.inf.br/hub?tenant=" + tenant,
    BASE_URL_WIDGET: "https://pms-api.cosmos.inf.br",
    FACEBOOK_APP_ID: "1164896447329254",
    FACEBOOK_APP_SECRET: "e7d0ba50c868965360e3a3276d8a98cd",
    FACEBOOK_VERIFICATION_TOKEN:
      "89bfab402f5a0b5062f436d9ab9f39861ba8242a68b4508e0d1296e520a93d08",
    FACEBOOK_APP_ACCESS_TOKEN: "1164896447329254|2zjs-0hApZrTB2dXkLj_mIxnUMQ",
    BASE_URL_SOCKET_WIDGET:
      "https://pms-api.cosmos.inf.br/hub/widget?tenant=" + tenant,
    URL_AWS_BUCKET:
      "https://cosmos-storage-pms-prod.s3.us-east-2.amazonaws.com/",
  };
} else if (window.location.hostname === "tel.cosmos.inf.br") {
  let tenant = "tel";
  ENV = {
    TENANT: tenant,
    APP_NAME: "Plataforma Digital",
    APP_ENV: "development",
    BASE_URL: "https://tel-api.cosmos.inf.br/api",
    BASE_URL_SOCKET: "https://tel-api.cosmos.inf.br/hub?tenant=" + tenant,
    BASE_URL_WIDGET: "https://tel.cosmos.inf.br",
    FACEBOOK_APP_ID: "1164896447329254",
    FACEBOOK_APP_SECRET: "e7d0ba50c868965360e3a3276d8a98cd",
    FACEBOOK_VERIFICATION_TOKEN:
      "89bfab402f5a0b5062f436d9ab9f39861ba8242a68b4508e0d1296e520a93d08",
    FACEBOOK_APP_ACCESS_TOKEN: "1164896447329254|2zjs-0hApZrTB2dXkLj_mIxnUMQ",
    BASE_URL_SOCKET_WIDGET:
      "https://tel-api.cosmos.inf.br/hub/widget?tenant=" + tenant,
    URL_AWS_BUCKET:
      "https://cosmos-storage-tel-prod.s3.us-east-2.amazonaws.com/",
  };
}else if (window.location.hostname === "opening.cosmos.inf.br") {
  let tenant = "opn";
  ENV = {
    TENANT: tenant,
    APP_NAME: "Plataforma Digital",
    APP_ENV: "production",
    BASE_URL: "https://opening-api.cosmos.inf.br/api",
    BASE_URL_SOCKET: "https://opening-api.cosmos.inf.br/hub?tenant=" + tenant,
    BASE_URL_WIDGET: "https://opening-api.cosmos.inf.br",
    FACEBOOK_APP_ID: "1164896447329254",
    FACEBOOK_APP_SECRET: "e7d0ba50c868965360e3a3276d8a98cd",
    FACEBOOK_VERIFICATION_TOKEN:
      "89bfab402f5a0b5062f436d9ab9f39861ba8242a68b4508e0d1296e520a93d08",
    FACEBOOK_APP_ACCESS_TOKEN: "1164896447329254|2zjs-0hApZrTB2dXkLj_mIxnUMQ",
    BASE_URL_SOCKET_WIDGET:
      "https://opening-api.cosmos.inf.br/hub/widget?tenant=" + tenant,
    URL_AWS_BUCKET:
      "https://cosmos-storage-opening-prod.s3.us-east-2.amazonaws.com/",
  };
} else {
  ENV = {
    TENANT: "wtf",
    APP_NAME: "Plataforma Digital",
    APP_ENV: "production",
    BASE_URL: "https://localhost:7050/api",
    BASE_URL_SOCKET: "https://localhost:7050/hub?tenant=wtf",
    BASE_URL_WIDGET: "http://127.0.0.1:8080",
    FACEBOOK_APP_ID: "1164896447329254",
    FACEBOOK_APP_SECRET: "e7d0ba50c868965360e3a3276d8a98cd",
    FACEBOOK_VERIFICATION_TOKEN:
      "89bfab402f5a0b5062f436d9ab9f39861ba8242a68b4508e0d1296e520a93d08",
    FACEBOOK_APP_ACCESS_TOKEN: "1164896447329254|2zjs-0hApZrTB2dXkLj_mIxnUMQ",
    BASE_URL_SOCKET_WIDGET: "https://localhost:7050/hub/widget?tenant=wtf",
    URL_AWS_BUCKET:
      "https://cosmos-storage-wtf-prod.s3.us-east-2.amazonaws.com/anexos/69CD546F13.png",
  };
}

export { ENV };
