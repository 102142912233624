import { createRouter, createWebHistory } from 'vue-router'
import LayoutDefault from '../views/LayoutDefault.vue'
import LoginView from '../views/LoginView.vue'
import WidgetExterno from '../views/WidgetExterno.vue'

//-----UTILS
import CanUserAccessClass from '../utils/CanUserAccessClass'

let canUserAccessClass = new CanUserAccessClass();

const routes = [
  {
    path: '/',
    name: '/',
    component: LoginView
  },
  {
    path: '/widget-externo',
    name: '/widget-externo',
    component: WidgetExterno
  },
  {
    path: '/phone/call/:id',
    name: 'call',
    component: () => import('../pages/voice/PhoneCallPage.vue'),
    beforeEnter: (to, from, next) => {
      if(to.params.id) {
        next();
      }else{
        next({ name: to.name})
      }
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: LayoutDefault,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../pages/home/HomePage.vue')
      },
      {
        path: '/mailing',
        name: 'mailing',
        component: () => import('../pages/mailing/MailingPage.vue')
      },
      {
        path: '/company',
        name: 'company',
        component: () => import('../pages/company/CompanyPage.vue'),
        beforeEnter: (to, from, next) => {
          if(canUserAccessClass.canUserAccess(to.name)) {
            next();
          }else{
            next({ name: to.name})
          }
        }
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('../pages/user/UserPage.vue'),
        beforeEnter: (to, from, next) => {
          if(canUserAccessClass.canUserAccess(to.name)) {
            next();
          }else{
            next({ name: to.name})
          }
        }
      },
      {
        path: '/group',
        name: 'group',
        component: () => import('../pages/group/GroupPage.vue'),
        beforeEnter: (to, from, next) => {
          if(canUserAccessClass.canUserAccess(to.name)) {
            next();
          }else{
            next({ name: to.name})
          }
        }
      },
      {
        path: '/channel',
        name: 'channel',
        component: () => import('../pages/channel/ChannelPage.vue'),
        beforeEnter: (to, from, next) => {
          if(canUserAccessClass.canUserAccess(to.name)) {
            next();
          }else{
            next({ name: to.name})
          }
        }
      },
      {
        path: '/queue',
        name: 'queue',
        component: () => import('../pages/queue/QueuePage.vue'),
        beforeEnter: (to, from, next) => {
          if(canUserAccessClass.canUserAccess(to.name)) {
            next();
          }else{
            next({ name: to.name})
          }
        }
      },
      {
        path: '/flow',
        name: 'flow',
        component: () => import('../pages/flow/FlowPage.vue'),
        beforeEnter: (to, from, next) => {
          if(canUserAccessClass.canUserAccess(to.name)) {
            next();
          }else{
            next({ name: to.name})
          }
        }
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('../pages/contact/ContactPage.vue'),
        beforeEnter: (to, from, next) => {
          if(canUserAccessClass.canUserAccess(to.name)) {
            next();
          }else{
            next({ name: to.name})
          }
        }
      },
      {
        path: '/settings',
        name: 'settings',
        component: () => import('../pages/settings/SettingsPage.vue'),
        beforeEnter: (to, from, next) => {
          if(canUserAccessClass.canUserAccess(to.name)) {
            next();
          }else{
            next({ name: to.name})
          }
        }
      },
      {
        path: '/report',
        name: 'report',
        component: () => import('../pages/report/ReportPage.vue'),
        beforeEnter: (to, from, next) => {
          if(canUserAccessClass.canUserAccess(to.name)) {
            next();
          }else{
            next({ name: to.name})
          }
        }
      },
      {
        path: '/chat',
        name: 'chat',
        component: () => import('../pages/chat/ChatPage.vue'),
        beforeEnter: (to, from, next) => {
          if(canUserAccessClass.canUserAccess(to.name)) {
            next();
          }else{
            next({ name: to.name})
          }
        }
      },
      {
        path: '/ticket',
        name: 'ticket',
        component: () => import('../pages/ticket/TicketPage.vue'),
        beforeEnter: (to, from, next) => {
          if(canUserAccessClass.canUserAccess(to.name)) {
            next();
          }else{
            next({ name: to.name})
          }
        }
      },
      {
        path: '/flow-tabulation',
        name: 'flow-tabulation',
        component: () => import('../pages/flow/FlowTabulation.vue')
      },
      {
        path: '/phone',
        redirect: '/phone/status',
        children: [
          {
            path: 'status',
            name: 'phone',
            component: () => import('../pages/voice/VoiceServicePage.vue'),
            beforeEnter: (to, from, next) => {
              if(canUserAccessClass.canUserAccess(to.name) && canUserAccessClass.isUserAgent()) {
                next();
              }else{
                next({ name: 'phone-dashboard'})
              }
            }, 
            },
            {
              path: 'dashboard',
              name: 'phone-dashboard',
              component: () => import('../pages/voice/PhoneDashboard.vue'),
              beforeEnter: (to, from, next) => {
                if(!canUserAccessClass.isUserAgent()) {
                  next();
                }else{
                  next({ name: 'phone'})
                }
              }, 
            },
          ]
        }
    ]
  },
]

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})